<script lang="ts" setup>
const props = defineProps<{
  date?: string | null;
  time?: boolean;
  relative?: boolean;
  reduced?: boolean;
}>();
const formatDate = useFormatDate();
const formatted = computed(() => {
  return formatDate({
    date: props.date,
    time: props.time,
    relative: props.relative,
    reduced: props.reduced
  });
});
</script>
<template>
  <time v-if="date" :datetime="date">{{ formatted }}</time>
</template>
